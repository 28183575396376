import React, { useMemo, useState, useEffect } from 'react';
import { Spinner } from 'baseui/spinner';
import axios from 'axios';
import { useStyletron } from 'baseui';
import { getServerUrl } from '../apollo/client';
import { Button } from 'baseui/button';
import LoginContainer from '../components/loginContainer';
import { Cell } from 'baseui/layout-grid';
import jwt_decode from "jwt-decode";
import {
  LabelMedium
} from 'baseui/typography';
import { Input } from 'baseui/input';
import PasswordStrengthBar from 'react-password-strength-bar';
import { adminSliders, userSliders } from '../helpers/slidersData';
import Check from 'baseui/icon/check';
import Delete from 'baseui/icon/delete';
import { BUTTON_COLOR, TEXT_COLOR } from '../util/colorConstants';

export const passwordSeparateValidtions = (password = '') => {
  let value = (password || '').trim();

  var expressions = {
    LOWER_ALPHA: /[a-z]/,
    UPPERCASE_ALPHA: /[A-Z]/,
    NUMERIC: /[0-9]/,
    ALPHANUMERIC: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
    SPECIAL_CHARACTER: /[@$!%*?&]/
  };
  var lower_alpha = false;
  var upper_alpha = false;
  var numeric = false;
  var alphanumeric = false;
  var special_character = false;
  var password_length = false;

  if (expressions['LOWER_ALPHA'].test(value)) {
    console.log(value, expressions['LOWER_ALPHA'])
    lower_alpha = true
  }
  if (expressions['UPPERCASE_ALPHA'].test(value)) {
    upper_alpha = true
  }
  if (expressions['NUMERIC'].test(value)) {
    numeric = true
  }
  if (expressions['ALPHANUMERIC'].test(value)) {
    alphanumeric = true
  }
  if (expressions['SPECIAL_CHARACTER'].test(value)) {
    special_character = true
  }
  if (value.length > 8 && !password.includes(' ')) {
    password_length = true;
  }

  console.log('passwordSeparateValidtions', value, {
    lower_alpha,
    upper_alpha,
    numeric,
    // alphanumeric,
    special_character,
    password_length
  })

  return {
    lower_alpha,
    upper_alpha,
    numeric,
    // alphanumeric,
    special_character,
    password_length
  }
};

export const PasswordStrengthCardware = ({ password, style, setIsPasswordValid }) => {
  const [passwordValidation, setPasswordValidation] = useState({});

  useEffect(() => {
    const validation = passwordSeparateValidtions(password);
    setPasswordValidation(validation);
  }, [password]);

  useEffect(() => {
    setIsPasswordValid(Object.keys(passwordValidation).reduce((a, b) => {
      return a && passwordValidation[b];
    }, true));
  }, [passwordValidation]);

  return <div style={style}>
    <div>
      Password must contain the following:
    </div>
    <PasswordValidationIndicator
      title='A lowercase letter'
      isValid={passwordValidation?.lower_alpha}
    />
    <PasswordValidationIndicator
      title={'A capital (uppercased) letter'}
      isValid={passwordValidation?.upper_alpha}
    />
    <PasswordValidationIndicator
      title={'A number'}
      isValid={passwordValidation?.numeric}
    />
    <PasswordValidationIndicator
      title={'A special character (@$!%*?&)'}
      isValid={passwordValidation?.special_character}
    />
    <PasswordValidationIndicator
      title={'Minimum 8 characters'}
      isValid={passwordValidation?.password_length}
    />
  </div>
}

const PasswordValidationIndicator = ({ title, isValid }) => {
  const [css, theme] = useStyletron();
  const Icon = useMemo(() => {
    return isValid ? Check : Delete
  }, [isValid]);

  return (
    <div style={{
      display: 'flex', flexDirection: 'row'
    }}>
      <Icon size={24} className={css({ color: isValid ? 'green' : 'red' })} />
      <div style={{ marginLeft: 8 }}>
        {title}
      </div>
    </div>
  )
}

const AcceptInvite = () => {
  const [css, theme] = useStyletron();
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get('name');
  const token = urlParams.get('token');
  const isInvitation = urlParams.get('isInvitation');
  const decoded = jwt_decode(token);
  const type = decoded.isAdmin ? 'admin' : 'user';
  const [isLoading, setIsLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isPasswordValid, setIsPasswordValid] = React.useState(false);
  const onsubmit = () => {
    if (!password) return;
    if (password !== confirmPassword) return;
    setIsButtonLoading(true);
    let fromInvitation = isInvitation && isInvitation || true;
    axios({
      url: `${getServerUrl()}/reset-password?token=${token}&domain=${window.location.origin}`,
      method: 'POST',
      data: {
        password,
        fromInvitation
      }
    }).then((responseJson) => {
      if (responseJson.data.success) {
        // debugger;
        // setIsButtonLoading(false);
        window.location.href = responseJson.data.result_url;
        return;
      };
      setError(responseJson.data.message);
      setIsLoading(false);
      setIsButtonLoading(false);
    }).catch((err) => {
      setIsButtonLoading(false);
      setError(err.message);
    })
  };

  React.useEffect(() => {
    if (password === '' || confirmPassword === '') return;
    if (password !== confirmPassword) {
      // setError('Confirm Password and Password should be same');
      setError('Both passwords entered must match');
    };
    if (password === confirmPassword) {
      setError(null);
    }
  }, [password, confirmPassword])

  const sliders = type === 'admin' ? adminSliders : userSliders;

  return (
    <>
      <LoginContainer hasSliders={false} sliders={sliders} sloganSub={`${name}`} boxShadowSlider={false}>
        {isLoading ? <>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              color: theme.colors.primaryA,
            }}
          >
            Setting up Invitation
        </div>
          <div
            className={css({
              position: 'absolute',
              width: theme.sizing.scale1200,
              height: theme.sizing.scale1200,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            <Spinner />
          </div>
        </> :
          <>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                color: TEXT_COLOR || theme.colors.primaryA,
              }}
            >
              Please enter a new password
            </div>
            <Cell
              span={12}
              overrides={{
                Cell: {
                  style: () => ({
                    marginTop: '32px',
                  }),
                },
              }}
            >
              <Input
                value={password}
                placeholder="Password"
                onChange={e => setPassword(e.target.value)}
                type={"password"}
                clearOnEscape
                autoComplete={'off'}
              />
              <div style={{ marginTop: '16px' }}></div>
              {/* <PasswordStrengthBar password={password} /> */}
              <Input
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={e => setConfirmPassword(e.target.value)}
                type={"password"}
                clearOnEscape
                autoComplete={'off'}
              />
              <PasswordStrengthCardware password={password} style={{ marginTop: 16, color: TEXT_COLOR || 'black' }} setIsPasswordValid={(value) => {
                setIsPasswordValid(value)
              }} />
              <Button
                overrides={{
                  BaseButton: { style: { width: '100%', marginTop: '16px', background: BUTTON_COLOR  } },
                }}
                isLoading={isButtonLoading}
                onClick={() => onsubmit()}
                disabled={!isPasswordValid}
              >
                Set New Password
              </Button>
              {error && <LabelMedium
                style={{ color: theme.colors.warning, marginTop: '8px', textAlign: 'center' }}
              >
                {error}
              </LabelMedium>}
            </Cell>
          </>}
      </LoginContainer>
    </>
  );
};

export default AcceptInvite;
